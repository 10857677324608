import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Client } from '../../api-clients/pyjam/client';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'extra-work-description',
  templateUrl: './extra-work-description.component.html',
  styleUrls: ['./extra-work-description.component.scss'],
})
export class ExtraWorkDescriptionComponent implements OnInit {
  public form: FormGroup;
  public currentDate = new Date();
  public maxDate: Date;
  private replyId: number;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private client: Client,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.maxDate.setDate(new Date().getDate() + 7);

    this.replyId = this.activatedRoute.snapshot.params?.id;
    this.form = new FormGroup({
        description: new FormControl('', [Validators.required]),
        price: new FormControl(null, [Validators.min(1), Validators.max(999999), Validators.required]),
        checkValuePrice: new FormControl(false, []),
        deadline: new FormControl(null),
        asapCheck: new FormControl(false),
      },
      this.deadlineValidator()
    );

    console.log(this.form);

    // this.form.controls['asapCheck'].valueChanges.subscribe(value => {
    //   if (value) {
    //     this.form.controls['deadline'].setValue(null);
    //   }
    // });

    // this.form.controls['deadline'].valueChanges.subscribe(value => {
    //   if (value) {
    //     this.form.controls['asapCheck'].setValue(false);
    //   }
    // });
  }

  public async onReplyExtraWorkClick(): Promise<void> {
    console.log('onReplyExtraWorkClick');
    try {
      await this.loadingService.start();

      await firstValueFrom(this.client.extraWorkPost(
        this.replyId,
        this.form.controls.price.value,
        this.form.controls.description.value,
        this.form.controls.deadline.value,
        undefined
      ));

      await this.navigationService.goBack();
    } catch (error) {
      this.translate.get(error.result.message || 'chat.messages.requestError').subscribe((message: string): void => {
        this.toastService.error(message);
      });
    } finally {
      await this.loadingService.stop();
    }
  }

  public onInputKeyDown($event: KeyboardEvent): void {
    if ($event.key == 'ArrowRight' || $event.key == 'ArrowDown'
      || $event.key == 'ArrowLeft' || $event.key == 'ArrowUp'
      || $event.key == 'Delete' || $event.key == 'Backspace'
      || $event.key == '0' || $event.key == '1'
      || $event.key == '2' || $event.key == '3'
      || $event.key == '4' || $event.key == '5'
      || $event.key == '6' || $event.key == '7'
      || $event.key == '8' || $event.key == '9') {
      if (($event.key == '0' || $event.key == '1'
          || $event.key == '2' || $event.key == '3'
          || $event.key == '4' || $event.key == '5'
          || $event.key == '6' || $event.key == '7'
          || $event.key == '8' || $event.key == '9')
        && this.form.controls?.price?.value?.toString()?.length >= 6) {
        $event.preventDefault();
      }
    } else {
      $event.preventDefault();
    }
  }

  public checkValuePriceChanged($event: any): void {
    if ($event.detail.checked) {
      this.form.controls.price.disable();
      this.form.controls.price.setValue(null);
      this.form.controls.price.addValidators(Validators.required);
    } else {
      this.form.controls.price.enable();
      this.form.controls.price.removeValidators(Validators.required);
    }
  }

  public onLabelClick($event: any): void {
    // this.form.controls.asapCheck.setValue(!this.form.controls.asapCheck.value);
    // this.form.markAsTouched();
    console.log('checked', this.form.controls.deadline);

    if ($event.detail.checked) {
      this.form.controls.deadline.disable();
      this.form.controls.deadline.setValue(null);
    } else {
      this.form.controls.deadline.enable();
    }
  }

  public deadlineValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const deadline = group.controls['deadline'];
      const asapCheck = group.controls['asapCheck'];

      if (!deadline.value && !asapCheck.value) {
        asapCheck.setErrors({chooseDeadline: true});
      } else {
        asapCheck.setErrors(null);
      }
      return;
    };
  }
}
