<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.extraWork.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-start ion-padding-end">
  <form *ngIf="form" [formGroup]="form" class="custom-form">
    <h2 class="title">{{ 'reply.extraWork.description' | translate }}</h2>
    <div class="field">
      <ion-textarea formControlName="description"
                    class="custom-ion-textarea"
                    name="textarea"
                    [placeholder]="'reply.extraWork.description' | translate"
                    rows="6"
                    maxlength="1500">
      </ion-textarea>
      <div *ngIf="form.touched && form.controls.description.errors?.required" class="error">
        {{ 'reply.extraWork.requiredField' | translate }}
      </div>
    </div>

    <div class="field check">
      <ion-toggle formControlName="checkValuePrice"
                  slot="start"
                  (ionChange)="checkValuePriceChanged($event)">
      </ion-toggle>
      <ion-label>{{ 'reply.extraWork.additionalPayment' | translate }}</ion-label>
    </div>
    <div class="field price" (click)="$event.stopImmediatePropagation()">
      <ion-input type="number"
                 maxlength="6"
                 inputMode="number"
                 formControlName="price"
                 class="custom-ion-input"
                 [placeholder]="'reply.extraWork.price' | translate"
                 (keydown)="onInputKeyDown($event)">
      </ion-input>
      <div *ngIf="form.touched && !form.controls.price.value && !form.controls.checkValuePrice.value" class="error">
        {{ 'reply.extraWork.requiredField' | translate }}
      </div>
      <div *ngIf="form.touched && form.controls.price.errors?.min" class="error">
        {{ 'reply.extraWork.min' | translate }}
      </div>
      <div *ngIf="form.touched && form.controls.price.errors?.max" class="error">
        {{ 'reply.extraWork.max' | translate }}
      </div>
    </div>

    <!-- deadline -->

    <div class="field datetime">
      <!-- <div class="check-slot">
        <ion-checkbox name="check-box"
                      color="dark"
                      formControlName="asapCheck">
        </ion-checkbox>
        <div class="ion-align-items-center" (click)="onLabelClick()">
          {{ 'task.add.deadline.fast' | translate }}
        </div>
      </div> -->

      <div class="field check">
        <ion-toggle formControlName="asapCheck"
                    slot="start"
                    (ionChange)="onLabelClick($event)">
        </ion-toggle>
        <ion-label>{{ 'reply.extraWork.offerDeadline' | translate }}</ion-label>
      </div>

      <div class="deadline-slot">
        <ion-label position="fixed" slot="start">
          {{ 'task.add.deadline.deadline' | translate }}
        </ion-label>
        <ion-input readonly="true"
                   slot="start"
                   class="custom-ion-input"
                   [placeholder]="'task.add.deadline.deadlinePlaceholder' | translate"
                   type="text"
                   [value]="form.controls.deadline.value?.toString() | date : 'd MMMM YYYY' : '' : translate.currentLang"
                   (click)="form.controls.deadline.disabled ? '' : dateTime.openDatePickerForControl(form.controls.deadline, false, currentDate.toISOString(), maxDate.toISOString())">
        </ion-input>
      </div>
      <div *ngIf="form.touched && !form.controls.deadline.value && !form.controls.asapCheck.value" class="error">
        {{ 'reply.extraWork.requiredField' | translate }}
      </div>
    </div>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-button class="ion-margin button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="onReplyExtraWorkClick.bind(this)"
              [disabled]="form?.invalid">
    {{ 'reply.extraWork.continue' | translate }}
  </ion-button>
</ion-footer>

<app-date-time #dateTime></app-date-time>