import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private platformService: PlatformService,
  ) {
  }

  public get introPassed(): boolean {
    if (this.platformService.isSSR) return true;

    return this.getItem('introPassed');
  }

  public get tutorialControlPassed(): boolean {
    if (this.platformService.isSSR) return true;

    return this.getItem('tutorialControlPassed');
  }

  public setItem(key: string, value: any): void {
    if (typeof localStorage === 'undefined') return;

    const stringValue: string = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  public getItem(key: string): any | null {
    if (typeof localStorage === 'undefined') return null;

    const item: string | null = localStorage.getItem(key);

    return (item !== null) ? JSON.parse(item) : null;
  }

  public removeItem(key: string): void {
    if (typeof localStorage === 'undefined') return;

    localStorage.removeItem(key);
  }
}
