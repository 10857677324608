import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ChatDataResponse,
  ChatRequest,
  Client,
  NotificationResponse,
  NotificationsDataResponse
} from '../../api-clients/pyjam/client';
import { LoadingService } from '../../services/loading.service';
import { ToastService } from '../../services/toast.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { WebSocketController } from '../../services/web-socket.controller';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { NOTIFICATION_TYPE } from '../../app.constants';
import { firstValueFrom, Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';
import { ReplyStatus } from 'src/app/reply/reply.constants';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public readonly NOTIFICATION_TYPE = NOTIFICATION_TYPE;
  public readonly ReplyStatus = ReplyStatus;
  public notifications: NotificationResponse [] = [];
  public lastLoadedPage: number = 1;
  public countOnPage: number = 20;
  private notificationSubscription: Subscription;
  notificationIcons = {
    [NOTIFICATION_TYPE.MESSAGE]: 'add-outline',
    [NOTIFICATION_TYPE.COMMENT]: 'add-outline',
    [NOTIFICATION_TYPE.REPLY]: 'add-outline',
    [NOTIFICATION_TYPE.OFFER]: 'add-outline',
    [NOTIFICATION_TYPE.STATUS]: 'add-outline',
    [NOTIFICATION_TYPE.TASK]: 'add-outline',
    [NOTIFICATION_TYPE.CHAT]: 'add-outline',
    [NOTIFICATION_TYPE.EXTRA_WORK]: 'information-circle-outline',
    [NOTIFICATION_TYPE.PAYMENT_SUCCEEDED]: 'information-circle-outline',
    [NOTIFICATION_TYPE.ONBOARDING]: 'information-circle-outline',
    [NOTIFICATION_TYPE.INACTIVITY_OWNER]: 'information-circle-outline',
    [NOTIFICATION_TYPE.EXTRA_WORK_OFFER_EXTRA_PAY]: 'information-circle-outline',
    [NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE]: 'information-circle-outline',
    [NOTIFICATION_TYPE.EXTRA_WORK_PAID]: 'information-circle-outline',
    [NOTIFICATION_TYPE.EXTRA_WORK_WORKER_APPROVE]: 'information-circle-outline',
    [NOTIFICATION_TYPE.INACTIVITY_DEADLINE]: 'alert-outline',
    [NOTIFICATION_TYPE.INACTIVITY_EXTRA_WORK]: 'alert-outline',
    [NOTIFICATION_TYPE.DISPUTED]: 'alert-outline',
    [NOTIFICATION_TYPE.OFFER_STATUS_REJECTED]: 'alert-outline',
    [NOTIFICATION_TYPE.DEADLINE]: 'alert-outline',
    [NOTIFICATION_TYPE.EXTRA_WORK_REJECTED]: 'alert-outline',
    [NOTIFICATION_TYPE.PAYMENT_FAILED]: 'alert-outline',
    [NOTIFICATION_TYPE.ACCOUNT_PAYPAL_INVALID]: 'alert-outline',
  };

  constructor(
    public authService: AuthService,
    private client: Client,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private router: Router,
    private notificationService: NotificationService,
    private webSocketController: WebSocketController,
    private translate: TranslateService,
    private navController: NavController,
  ) {
  }

  ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.loadNotificationData(1).then();
    }

    this.notificationSubscription = this.webSocketController.notificationEvent.subscribe(res => {
      switch (res.type) {
        case 'notification': {
          this.notifications.unshift(res.data);
          break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.notificationSubscription?.unsubscribe();
  }

  public async loadNotificationData(page): Promise<NotificationsDataResponse | void> {
    return this.loadNotifications(page)
      .then((notifications: NotificationsDataResponse): void => {
        if (notifications.data.length > 0) {
          this.lastLoadedPage = 1;
        }
        this.notifications = [...notifications.data];
        this.read();
      });
  }

  public loadNotifications(page: number): Promise<NotificationsDataResponse> {
    return this.getNotifications(page) as Promise<NotificationsDataResponse>;
  }

  public async getNotifications(page: number): Promise<NotificationsDataResponse | void> {
    await this.loadingService.start();

    return await firstValueFrom(this.client.notificationIndex(page))
      .then((result: NotificationsDataResponse) => result)
      .catch(async error => {
        console.error(error);
        // await this.httpErrorHandlerService.handleHttpException(error);
      })
      .finally((): void => {
        this.loadingService.stop();
      });
  }

  public onScrollDown(): void {
    this.loadNotifications(this.lastLoadedPage + 1)
      .then(async (notifications: NotificationsDataResponse): Promise<void> => {
        if (notifications.data.length > 0) {
          this.lastLoadedPage++;
          this.notifications.push(...notifications.data);
          await this.read();
        }
      });
  }

  public async read(): Promise<void> {
    if (this.notifications.filter((notification: NotificationResponse) => !notification.is_read).length == 0) return;

    await this.loadingService.start();

    const body: number[] = this.notifications
      .filter((notification: NotificationResponse) => !notification.is_read)
      .map((notification: NotificationResponse) => notification.id);

    await firstValueFrom(this.client.notificationRead(body))
      .then((): void => {
        this.notificationService.countNotifications = 0;
      })
      .catch(async (error): Promise<void> => {
        this.translate.get('notifications.error', {value: error}).subscribe(async (t): Promise<void> => await this.toastService.error(t));
      })
      .finally((): void => {
        this.loadingService.stop();
      });
  }

  public async delete(event: Event, id: number, i: number): Promise<void> {
    event.stopPropagation();

    await this.loadingService.start();

    await firstValueFrom(this.client.notificationDelete(id))
      .then((): void => {
        this.notifications.splice(i, 1);
      })
      .catch(async (error): Promise<void> => {
        this.translate.get('notifications.error', {value: error}).subscribe(async (t): Promise<void> => await this.toastService.error(t));
      })
      .finally((): void => {
        this.loadingService.stop();
      });
  }

  public async check(notification): Promise<void> {
    switch (notification.type) {
      case NOTIFICATION_TYPE.MESSAGE:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.COMMENT:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId, 'comments']);
        }
        break;
      case NOTIFICATION_TYPE.REPLY:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.OFFER:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.STATUS:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.TASK:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.CHAT:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.PAYMENT_SUCCEEDED:
        if (notification.body?.paymentId) {
          await this.router.navigate(['profile/balance']);
        }
        break;
      case NOTIFICATION_TYPE.ONBOARDING:
        // if (notification.body?.onboardingId) {
        //  await this.router.navigate(['profile/balance']);
        // }
        break;
      case NOTIFICATION_TYPE.INACTIVITY_EXTRA_WORK:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.INACTIVITY_DEADLINE:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.INACTIVITY_OWNER:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.DISPUTED:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.OFFER_STATUS_ACCEPTED:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.OFFER_STATUS_REJECTED:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.DEADLINE:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_REJECTED:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_OFFER_EXTRA_PAY:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_PAID:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_WORKER_APPROVE:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_PENDING:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_WORKER_ADDED_RESULTS:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.REPLY_DATA_UPDATED:
        if (notification.body?.replyId) {
          await this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_WAIT_PAY:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.DISPUTE_CANCELED:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_DELETED:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE_RESULTS:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.OFFER_CREATE_TASK:
        if (notification.body?.categoryId) {
          await this.router.navigate(['tasks/add/category']);
        }
        break;
      case NOTIFICATION_TYPE.PAYMENT_FAILED:
        if (notification.body?.paymentId) {
          await this.router.navigate(['profile/balance']);
        }
        break;
      case NOTIFICATION_TYPE.PAYMENT_CANCELED:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.PAYMENT_AFTER_5_DAYS:
        if (notification.body?.chatId) {
          await this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case NOTIFICATION_TYPE.DISPUTED_TASK_FOR_TRUSTED_USER:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.PAY_FOR_TRUSTED_USER:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.PAYMENT_SUCCEEDED_REPLY_TIPS:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.PAYMENT_SUCCEEDED_AVATAR_ORDER_TIPS:
        if (notification.body?.avatarOrderId) {
          await this.router.navigate(['avatar-orders', notification.body?.avatarOrderId]); // TODO: avatar-orders page
        }
        break;
      case NOTIFICATION_TYPE.TASK_UNPUBLISHED:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case NOTIFICATION_TYPE.TASK_WILL_BE_UNPUBLISHED_AFTER_5_DAYS:
        if (notification.body?.taskId) {
          await this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
    }
  }

  public logInClick(): void {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem('noAuthBtn', '1');
      sessionStorage.removeItem('showMenu');
    }
    this.router.navigateByUrl('/login/sign-in').then((): boolean => true);
  }

  public async goToChat(user_id: number): Promise<void> {
    if (!user_id) {
      return;
    }

    let data = {
      user_2_id: user_id
    };

    await this.loadingService.start();

    this.client.chatPost(data as ChatRequest).subscribe(async (data: ChatDataResponse) => {
      await this.navController.navigateForward(['/chats', data.data.id]);
    }).add(() => this.loadingService.stop());
  }
}
