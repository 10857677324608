import { Inject, Injectable, Optional } from '@angular/core';
import {
  API_BASE_URL,
  ApiException,
  ChatResponse,
  Client,
  // FileParameter,
  FilesDataResponse,
  LangResponse,
  TaskAnswerRequest,
  TaskDataResponse,
  UserDataResponse,
  UserFilesDataResponse,
} from './client';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as _observableThrow } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SafeUrl } from '@angular/platform-browser';
import { AnswerForPush } from '../../task/task.controller';
// import { TokenStoreService } from '../../auth/token-store.service';

@Injectable()
export class ExtendedClient extends Client {
  private _http: HttpClient;
  private _baseUrl: string;

  constructor(
    // private tokenStore: TokenStoreService,
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    super(http, baseUrl);
    this._http = http;
    this._baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '/api';
  }

  taskPostExtended(body: ITaskPostExtendedRequest): Observable<TaskDataResponse> {
    let data: any = new FormData();
    data.append('category_id', body.category_id);
    data.append('name', body.name);
    if (body.price != 0) {
      data.append('price', body.price);
    }
    data.append('deadline', body.deadline ? 1 : 0);
    if (body.deadline_at) {
      data.append('deadline_at', body.deadline_at);
    }
    data.append('description', body.description);
    data.append('answers', JSON.stringify(body.answers));
    let i = 0;
    for (let file of body.files) {
      data.append(`files[${i}][file]`, file.file, file.fileName);
      data.append(`files[${i}][question_id]`, file.question_id);
      i++;
    }
    return this._http.post(this._baseUrl + '/task/', data, {responseType: 'blob'})
      .pipe(
        catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
        map((result: TaskDataResponse) => result),
      );
  }

  offerPost(body): Observable<any> {
    const data: any = new FormData();
    data.append('task_id', body.task_id);
    data.append('user_id', body.user_id);
    data.append('text', body.text);

    return this._http.post(this._baseUrl + '/offer/', data, {responseType: 'blob'})
      .pipe(
        catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
        map((result) => result),
      );
  }

  userUpdateExtended(id: number, body: IUserUpdateExtendedRequest): Observable<UserDataResponse> {
    let data: any = new FormData();

    if (body.name) {
      data.append('name', body.name);
    }

    if (body.surname) {
      data.append('surname', body.surname);
    }

    if (body.avatar || body.avatar === null) {
      data.append('avatar', body.avatar);
    }

    if (body.description != null) {
      data.append('description', body.description);
    }

    if (body.skills) {
      data.append('skills', JSON.stringify(body.skills));
    }

    if (body.lang) {
      data.append('lang', body.lang.id);
    }

    if (body.device_token) {
      data.append('device_token', body.device_token);
    }

    if (body.rate) {
      data.append('rate', body.rate);
    }

    if (body.rate === 0) {
      data.append('rate', '');
    }

    if (body.hasOwnProperty('isOpenForProposals')) {
      data.append('is_open_for_proposals', body.isOpenForProposals);
    }

    return this._http.post(this._baseUrl + '/user/update/' + id, data, {responseType: 'blob'})
      .pipe(
        catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
        map((result: UserDataResponse) => result),
      );
  }

  fileDownloadByIdExtended(id: number): Promise<Blob> {
    return this._http.get(this._baseUrl + '/file/download/' + id, {responseType: 'blob'}).toPromise();
  }

  /* async getFileDownloadUrl(id: number): Promise<string> {
    return this._baseUrl + '/file/download/' + id + '?token=' + await this.tokenStore.getToken();
  } */

  /*
    chatUpdateExtended(id: number, title: string | undefined, files: FileParameter[] | undefined): Observable<ChatDataResponse> {
      let data: any = new FormData();
      data.append('title', title);

      files.forEach((file, i) => {
        data.append(`files[${i}]`, file.data, file.fileName);
      });

      return this._http.post(this._baseUrl + `/chat/update/${id}`, data)
        .pipe(
          catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
          map((result: ChatDataResponse) => result),
        );
    }
  */

  chatIndexExtended(type: string | undefined, status: string | undefined, sort: string | undefined, order: string | undefined, countOnPage: number | undefined, page: number | undefined): Observable<any> {
    let data: any = new FormData();
    let url_ = '/chat/index?';
    if (type === null)
      throw new Error('The parameter \'type\' cannot be null.');
    else if (type !== undefined)
      url_ += 'type=' + encodeURIComponent('' + type) + '&';
    if (status === null)
      throw new Error('The parameter \'status\' cannot be null.');
    else if (status !== undefined)
      url_ += 'status=' + encodeURIComponent('' + status) + '&';
    if (sort === null)
      throw new Error('The parameter \'sort\' cannot be null.');
    else if (sort !== undefined)
      url_ += 'sort=' + encodeURIComponent('' + sort) + '&';
    if (order === null)
      throw new Error('The parameter \'order\' cannot be null.');
    else if (order !== undefined)
      url_ += 'order=' + encodeURIComponent('' + order) + '&';
    if (countOnPage === null)
      throw new Error('The parameter \'countOnPage\' cannot be null.');
    else if (countOnPage !== undefined)
      url_ += 'countOnPage=' + encodeURIComponent('' + countOnPage) + '&';
    if (page === null)
      throw new Error('The parameter \'page\' cannot be null.');
    else if (page !== undefined)
      url_ += 'page=' + encodeURIComponent('' + page) + '&';
    url_ = url_.replace(/[?&]$/, '');
    return this._http.get(this._baseUrl + url_, data).pipe(
      catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
      map((result) => result),
    );
  }

  /*
    profileFilePostExtended(files: FileParameterExtended[]): Observable<FilesDataResponse> {
      let data: any = new FormData();

      for (let i = 0; i < files.length; i++) {
        data.append('files[' + i + '][file]', files[i].data, files[i].fileName);
        data.append('files[' + i + '][description]', files[i].description);
      }
      return this._http.post(this._baseUrl + '/profileFile', data, {responseType: 'blob'})
        .pipe(
          catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
          map((result: FilesDataResponse) => result),
        );
    }
  */

  profileFileDescriptionChange(file): Observable<FilesDataResponse> {
    return this._http.put(this._baseUrl + `/profileFile/${file.id}`, {description: file.description})
      .pipe(
        catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
        map((result: FilesDataResponse) => result),
      );
  }

  userIndexExtended(taskId: number | undefined, categoryIds: number[] | undefined, taskAnswers: any[] | undefined, highRating: boolean | undefined, countOnPage: number | undefined, page: number | undefined, isWorkByPrice: boolean | undefined, isOpenForProposals: boolean | undefined): Observable<UserFilesDataResponse> {
    let url_ = '/user/index?';
    if (taskId === null)
      throw new Error('The parameter \'taskId\' cannot be null.');
    else if (taskId !== undefined)
      url_ += 'taskId=' + encodeURIComponent('' + taskId) + '&';
    if (categoryIds === null)
      throw new Error('The parameter \'categoryIds\' cannot be null.');
    else if (categoryIds !== undefined)
      categoryIds && categoryIds.forEach(item => {
        url_ += 'categoryIds[]=' + encodeURIComponent('' + item) + '&';
      });
    if (taskAnswers === null)
      throw new Error('The parameter \'taskAnswers\' cannot be null.');
    else if (taskAnswers !== undefined && taskAnswers.length)
      url_ += 'taskAnswers=' + JSON.stringify(taskAnswers) + '&';
    if (highRating === null)
      throw new Error('The parameter \'highRating\' cannot be null.');
    else if (highRating !== undefined)
      url_ += 'highRating=' + encodeURIComponent('' + highRating) + '&';
    if (countOnPage === null)
      throw new Error('The parameter \'countOnPage\' cannot be null.');
    else if (countOnPage !== undefined)
      url_ += 'countOnPage=' + encodeURIComponent('' + countOnPage) + '&';
    if (page === null)
      throw new Error('The parameter \'page\' cannot be null.');
    else if (page !== undefined)
      url_ += 'page=' + encodeURIComponent('' + page) + '&';
    if (isWorkByPrice === null)
      throw new Error('The parameter \'isWorkByPrice\' cannot be null.');
    else if (isWorkByPrice !== undefined)
      url_ += 'isWorkByPrice=' + encodeURIComponent('' + isWorkByPrice) + '&';
    if (isOpenForProposals === null)
      throw new Error('The parameter \'isOpenForProposals\' cannot be null.');
    else if (isOpenForProposals !== undefined)
      url_ += 'isOpenForProposals=' + encodeURIComponent('' + isOpenForProposals) + '&';
    url_ = url_.replace(/[?&]$/, '');

    return this._http.get(this._baseUrl + url_).pipe(
      catchError(ex => throwException(ex.message, ex.status, JSON.stringify(ex.error), ex.headers, ex)),
      map((result) => result),
    );
  }
}

export class ChatResponseExtended extends ChatResponse {
  last_message?: string;
  user_2_qty_unread_messages?: number;
  user_1_qty_unread_messages?: number;
}

function throwException(message: string,
                        status: number,
                        response: string,
                        headers: {
                          [key: string]: any;
                        },
                        result?: any): Observable<any> {
  return _observableThrow(new ApiException(message, status, response, headers, result));
}

export interface ITaskPostExtendedRequest {
  category_id?: number;
  name?: string;
  description?: string;
  price?: number;
  deadline?: boolean;
  deadline_at?: string;
  files?: ITaskFileRequest[];
  answers?: TaskAnswerRequest[];
}

/* export interface ITaskUpdateExtendedRequest {
  status_id?: number;
  name?: string;
  description?: string;
  price?: number;
  deadline?: boolean;
  deadline_at?: string;
  files?: ITaskFileRequest[];
  answers?: TaskAnswerRequest[];
  deletedFiles: [];
  deletedAnswers: [];
} */

export interface ITaskFileRequest {
  id?: number;
  file?: Blob;
  fileName: string;
  question_id?: number;
}

export interface IUserUpdateExtendedRequest {
  name?: string;
  surname?: string;
  description?: string;
  middlename?: number;
  birthday?: boolean;
  email?: string;
  avatar?: SafeUrl;
  device_token?: string;
  rate?: number;
  skills?: AnswerForPush[];
  lang?: LangResponse;
  isOpenForProposals?: boolean;
}

/* export interface FileParameterExtended extends FileParameter {
  description: string;
} */

export enum FileTypes {
  TYPE_PDF = 1,
  TYPE_AVATAR,
  TYPE_IMAGE,
  TYPE_AUDIO,
  TYPE_VIDEO,
  TYPE_OTHER = 99,
}
