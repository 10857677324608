import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { COMMIT_HASH, COMMIT_MESSAGE } from '../../../git_version';
import { Device } from '@capacitor/device';
import { PlatformService } from './platform.service';
import packageJson from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(
    private platformService: PlatformService,
  ) {
    this.showAppVersion().then();
  }

  async getAppInfo(): Promise<AppInfo | null> {
    if (typeof document === 'undefined') return;
    try {
      return await App.getInfo();
    } catch (error) {
      console.error('Error fetching app info:', error);
      return null;
    }
  }

  private async getVersionInfo(): Promise<{ version: string; build?: string }> {
    if (this.platformService.isDevice) {
      const appInfo: AppInfo = await this.getAppInfo();
      return {version: appInfo?.version, build: appInfo?.build};
    } else {
      return {version: packageJson?.version};
    }
  }

  public async showAppVersion(): Promise<void> {
    const {version, build} = await this.getVersionInfo();
    const massage: string = (this.platformService.isDevice)
      ? 'Application version: ' + version + ' (build: ' + build + ')'
      : 'Web version: ' + version;
    console.log('\x1b[33m' + massage + '\x1b[0m');
  }

  public async getShortAppVersion(): Promise<string> {
    const {version} = await this.getVersionInfo();
    return version;
  }

  public async getFullAppVersion(): Promise<string> {
    const {version, build} = await this.getVersionInfo();
    return (this.platformService.isDevice)
      ? 'App version: ' + version + ' (build: ' + build + ')'
      : 'Web app version: ' + version;
  }

  public async getLoginAppVersion(): Promise<string> {
    if (this.platformService.isDevice) {
      const {version} = await this.getVersionInfo();
      return version;
    } else {
      return 'web';
    }
  }

  public async getSupportVersion(): Promise<string> {
    if (!this.platformService.isDevice) return;

    const {version, build} = await this.getVersionInfo();
    return `Version: ${version} (build: ${build})`;
  }

  public async getSupportInfo(): Promise<string> {
    const {version, build} = await this.getVersionInfo();
    const gitVersion: string = 'commit hash: ' + COMMIT_HASH + ', commit message: ' + COMMIT_MESSAGE;
    let appVersion: string;
    let deviceInfo: string;

    try {
      if (this.platformService.isDevice) {
        appVersion = `${version} (build: ${build})`;
        deviceInfo = JSON.stringify(await Device.getInfo());
      } else {
        appVersion = version;

        if (typeof window !== 'undefined') {
          deviceInfo = JSON.stringify(window.navigator.userAgent);
        }
      }

      return `Device info: ${deviceInfo}\n
      ${this.platformService.isDevice ? `Application version: ${appVersion}\n` : `Web version: ${appVersion}\n`}
      Git version: ${gitVersion}\n
      ------------\n`;
    } catch (error) {
      console.error(error);
      return 'Error fetching support info';
    }
  }
}
