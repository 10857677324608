export enum MAP_MODE {
  MAP = 'map',
  AVATAR = 'avatar',
}

export enum MARKER_MODE {
  USERS = 'users',
  STREAMS = 'streams',
}

export enum WALK_MODE {
  NO_DATA = 'non-existent mode',
  FREE_WALK = 'free-walk',
  TIME_LIMITED = 'time-limited',
  FREE = 'free',
}

export enum WALK_MODE_NUMBERS {
  NO_DATA = 0,
  FREE_WALK = 1,
  TIME_LIMITED = 2,
  FREE = 3,
}

export function getWalkModeNumber(walkMode: string): number {
  switch (walkMode) {
    case WALK_MODE.FREE_WALK:
      return WALK_MODE_NUMBERS.FREE_WALK;
    case WALK_MODE.TIME_LIMITED:
      return WALK_MODE_NUMBERS.TIME_LIMITED;
    case WALK_MODE.FREE:
      return WALK_MODE_NUMBERS.FREE;

    default:
      console.error('walkMode: ', walkMode);
      return WALK_MODE_NUMBERS.NO_DATA;
  }
}

export function getWalkModeString(walkMode: number): string {
  switch (walkMode) {
    case WALK_MODE_NUMBERS.FREE_WALK:
      return WALK_MODE.FREE_WALK;
    case WALK_MODE_NUMBERS.TIME_LIMITED:
      return WALK_MODE.TIME_LIMITED;
    case WALK_MODE_NUMBERS.FREE:
      return WALK_MODE.FREE;

    default:
      console.error('walkMode: ', walkMode);
      return WALK_MODE.NO_DATA;
  }
}

export enum STATUS {
  ONLINE = 'online',
  BUSY = 'busy',
  OFFLINE = 'offline',
}

export enum STATE {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
}

export enum STATUS_NUMBERS {
  ONLINE = 1,
  BUSY = 2,
  OFFLINE = 3,
}

export enum STATUS_COLORS {
  ONLINE = '#33D256',
  BUSY = '#FF3737',
  OFFLINE = '#8E8D92',
  // DEFAULT = '#888888',
  SELECTED = '#0057FF',
  NO_DATA = '#ff6a00',
}

export enum STREAM_COLORS {
  LIVE = '#FF3737',
  NEW = '#0057FF', // 0% transparency
  OLD = 'rgba(0,87,255,0.6)', // 40% transparency
  VERY_OLD = 'rgba(0,87,255,0.2)', // 80% transparency
}

export function getStreamColor(live: boolean, date: string): string {
  if (live) return STREAM_COLORS.LIVE;

  const diffHours: number = getDiffHours(date);

  if (diffHours <= 24) {
    return STREAM_COLORS.NEW;
  } else if (diffHours <= 48) {
    return STREAM_COLORS.OLD;
  } else {
    return STREAM_COLORS.VERY_OLD;
  }
}

export function getStreamZIndex(date: string): number {
  const diffHours: number = getDiffHours(date);
  const safeDiff: number = diffHours > 0 ? diffHours : 0.001;
  return 100000 / safeDiff;
}

function getDiffHours(date: string): number {
  const oneHourMs: number = 60 * 60 * 1000; // 60 minutes
  const streamDate: number = new Date(date).getTime();
  const now: number = new Date().getTime();
  return (now - streamDate) / oneHourMs;
}

export function getStatusColor(statusNumber: number): string {
  switch (statusNumber) {
    case STATUS_NUMBERS.ONLINE:
      return STATUS_COLORS.ONLINE;
    case STATUS_NUMBERS.OFFLINE:
      return STATUS_COLORS.OFFLINE;
    case STATUS_NUMBERS.BUSY:
      return STATUS_COLORS.BUSY;

    default:
      console.error('statusNumber: ', statusNumber);
      return STATUS_COLORS.NO_DATA;
  }
}

export function getStatusNumber(statusString: string): number {
  switch (statusString) {
    case STATUS.ONLINE:
      return STATUS_NUMBERS.ONLINE;
    case STATUS.BUSY:
      return STATUS_NUMBERS.BUSY;
    case STATUS.OFFLINE:
      return STATUS_NUMBERS.OFFLINE;

    default:
      // console.error('statusString: ', statusString);
      return;
  }
}

export function getStatusString(statusNumber: number): string {
  switch (statusNumber) {
    case STATUS_NUMBERS.ONLINE:
      return STATUS.ONLINE;
    case STATUS_NUMBERS.BUSY:
      return STATUS.BUSY;
    case STATUS_NUMBERS.OFFLINE:
      return STATUS.OFFLINE;

    default:
      // console.error('statusString: ', statusString);
      return;
  }
}

export enum UPDATE_INTERVAL {
  MARKERS = 120_000,
  BATTERY = 30_000,
  MY_GPS = 30_000,
  TEST_GPS = 5_000,
  EXECUTOR_GPS = 7_500,
}

export enum ORDER_STATUS_NUMBERS {
  CREATED = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  COMPLETED = 4,
  PAID = 5,
  PAYMENT_FAILED = 6,
}

export enum MyReaction {
  LIKE = 'like',
  DISLIKE = 'dislike',
}