<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <app-save-button
        *ngIf="form.valid && isReplyEdited"
        (click) = "onSaveButtonClick()">
      </app-save-button>
    </ion-buttons>
    <ion-title>
     {{ 'reply.editReply.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-start ion-padding-end">
  <form *ngIf="form && task" [formGroup]="form">
    <div class="vs">
      <div class="owner">
        <h2> {{ 'reply.editReply.customer' | translate }}</h2>
        <div class="price">{{task.price | priceLocale}} {{ 'common.currency.rub' | translate }}</div>
        <div class="deadline">{{task.deadline_at| date : 'dd.MM.YYYY'}}</div>
      </div>
      <div class="replier">
        <h2> {{ 'reply.editReply.myOffer' | translate }}</h2>
        <div class="price">
          <ion-input type="number"
                     inputMode="number"
                     formControlName="price"
                     class="custom-ion-input"
                     [placeholder]="'reply.editReply.pricePlaceholder' | translate"
                     (keydown)="onInputKeyDown($event)">
          </ion-input>
          <div *ngIf="form.touched && form.controls.price.errors?.required" class="error">
            {{ 'reply.editReply.requiredPrice' | translate }}
          </div>
          <div *ngIf="form.touched && form.controls.price.errors?.min" class="error">
            {{ 'reply.editReply.minPrice' | translate }}
          </div>
          <div *ngIf="form.touched && form.controls.price.errors?.max" class="error">
            {{ 'reply.editReply.maxPrice' | translate }}
          </div>
        </div>
        <div class="deadline">
          <ion-input readonly="true"
                     class="custom-ion-input"
                     type="text"
                     [value]="form.controls.deadline.value.toString() | date : 'dd.MM.YYYY'"
                     (click)="dateTime.openDatePickerForControl(form.controls.deadline, false, this.currentDateTime.toISOString(), maxDate.toISOString())">
          </ion-input>
          <!--<div *ngIf="form.touched && form.controls.deadline.errors?.required" class="error">
            Дедлайн обязательно
          </div>-->
        </div>
      </div>
    </div>
<!--    <ion-textarea style="margin-top: 58px"-->
<!--                  [placeholder]="'reply.editReply.commentPlaceholder' | translate"-->
<!--                  formControlName="description"-->
<!--                  class="custom-ion-textarea"-->
<!--                  name="textarea"-->
<!--                  rows="6">-->
<!--    </ion-textarea>-->
  </form>
</ion-content>

<app-date-time #dateTime></app-date-time>
